import React, { useState } from 'react';

const DropdownButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showPromptInput, setShowPromptInput] = useState(false);
  const [prompt, setPrompt] = useState('');
  
  const toggleDropdown = () => setIsOpen(!isOpen);
  
  const handleCustomChartClick = () => {
    setIsOpen(false);
    setShowPromptInput(true);
  };

  const handleSubmit = () => {
    console.log('Submitted prompt:', prompt);
    setPrompt('');
    setShowPromptInput(false);
  };

  const handleCancel = () => {
    setPrompt('');
    setShowPromptInput(false);
  };

  const styles = {
    
    dropdownIcon: {
      width: '20px',
      height: '20px',
      transition: 'transform 0.2s ease',
    },
    rotate: {
      transform: 'rotate(180deg)',
    },
   
    promptContainer: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      padding: '30px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      zIndex: 1001,
      width: '600px',
      minHeight: '300px',
    },
    input: {
      width: '100%',
      padding: '15px',
      marginBottom: '20px',
      border: '1px solid #ddd',
      borderRadius: '4px',
      fontSize: '16px',
      minHeight: '150px',
      resize: 'vertical',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '10px',
    },
    sendButton: {
      padding: '12px 24px',
      backgroundColor: '#4a90e2',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '14px',
    },

    cancelButton: {
      padding: '12px 24px',
      backgroundColor: '#ffffff',
      color: '#4a90e2',
      border: '1px solid #4a90e2',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '14px',
    },
    title: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '20px',
      color: '#333',
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.3)", // Dark overlay
      backdropFilter: "blur(8px)", // Blurring effect
      zIndex: 1000, // Behind the prompt input but above everything else
    },
    
  };

  return (
    <div className="dropdown-container">
      <button onClick={toggleDropdown} className="dropdown-button">
        Create Grafana Chart
        <svg 
          style={{
            ...styles.dropdownIcon,
            ...(isOpen ? styles.rotate : {})
          }}
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M19 9l-7 7-7-7" 
          />
        </svg>
      </button>
      
      {isOpen && (
       <div className="dropdown-menu">
          <button 
            className="dropdown-item"
            onClick={handleCustomChartClick}
          >
            generate custom charts
          </button>
          <button 
           className="dropdown-item"
            onClick={() => {
              console.log('Button 2 clicked');
              setIsOpen(false);
            }}
          >
            generate default charts
          </button>
        </div>
      )}

      {showPromptInput && (
        <>
        <div style={styles.overlay}></div> 
        <div style={styles.promptContainer}>
          <div style={styles.title}>Enter Custom Chart Prompt</div>
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Enter your prompt here..."
            style={styles.input}
          />
          <div style={styles.buttonContainer}>
            <button onClick={handleCancel} style={styles.cancelButton}>
              Cancel
            </button>
            <button onClick={handleSubmit} style={styles.sendButton}>
              Send
            </button>
          </div>
        </div>
        </>
      )}
    </div>
    
  );
};

export default DropdownButton;