import React, { useState } from "react";
import { useDrag } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFont,
  faCheckSquare,
  faDotCircle,
  faCaretSquareDown,
  faCalendarAlt,
  faAlignLeft,
  faEnvelope,
  faHashtag,
  faKey,
  faUpload,
  faClipboardCheck,
  faBars,
  faTimes
} from "@fortawesome/free-solid-svg-icons";

const ToolboxItem = ({ itemType, label, icon }) => {
  const [, drag] = useDrag(() => ({
    type: itemType,
    item: { type: itemType, label },
  }));

  return (
    <div
      className="toolbox-item"
      ref={drag}
      style={{
        padding: "10px",
        border: "1px solid #ccc",
        marginBottom: "3px",
        display: "flex",
        alignItems: "center",
        borderRadius: "4px",
        transition: "backgroundColor 0.3s, transform 0.2s",
        cursor: "pointer",
        hover: {
          backgroundColor: "#e0e0e0",
          transform: "scale(1.02)"
        }
      }}
    >
      <FontAwesomeIcon icon={icon} style={{ marginRight: "8px", color: "#9588a6" }} />
      {label}
    </div>
  );
};

const Toolbox = (onToggle ) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const items = [
    { type: "text", label: "Text Field", icon: faFont },
    { type: "checkbox", label: "Checkbox", icon: faCheckSquare },
    { type: "radio", label: "Radio Button", icon: faDotCircle },
    { type: "dropdown", label: "Dropdown", icon: faCaretSquareDown },
    { type: "date", label: "Date Picker", icon: faCalendarAlt },
    { type: "textarea", label: "Text Area", icon: faAlignLeft },
    { type: "email", label: "Email Field", icon: faEnvelope },
    { type: "number", label: "Number Field", icon: faHashtag },
    { type: "password", label: "Password Field", icon: faKey },
    { type: "file", label: "File Upload", icon: faUpload },
    { type: "button", label: "Button", icon: faClipboardCheck },
  ];
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
    onToggle && onToggle(!isCollapsed);
  };

  return (
<div 
  style={{ display: 'flex' }}
  onMouseEnter={() => setIsHovered(true)}
  onMouseLeave={() => setIsHovered(false)}
>
  {/* Toolbox */}
  <div
    style={{
      width: isVisible ? "300px" : "0",
      height: "400px", // Set a fixed height for the toolbox
      padding: isVisible ? "16px" : "0",
      overflow: "hidden", // Ensure no overflow when hidden
      transition: "width 0.3s ease-in-out, padding 0.3s ease-in-out", // Smooth transition for width and padding
      position: "relative", // Position context for the inner scrollable area
    }}
  >
    <h3 style={{ margin: "8px 0", textAlign: 'center', fontSize: "1.5em", color: "#333" }}>
      Toolbox
    </h3>
    <div
      style={{
        maxHeight: "calc(400px - 60px)", 
        overflowY: "auto", 
        paddingRight: "8px", 
        marginTop: "8px", 
        // scrollbarWidth: "none", 
        // "-ms-overflow-style": "none", 
      }}
      className="scrollable"
    >
      {items.map((item) => (
        <ToolboxItem
          key={item.type}
          itemType={item.type}
          label={item.label}
          icon={item.icon}
        />
      ))}
    </div>
  </div>

  {/* Toggle Button - Only visible on hover */}
  <button
    onClick={() => setIsVisible(!isVisible)}
    style={{
      height: "40px",
      marginLeft: "10px",
      marginTop: "10px",
      padding: "0 12px",
      backgroundColor: "#2B2E48",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "all 0.2s ease",
      opacity: isHovered ? "1" : "0",
      pointerEvents: isHovered ? "auto" : "none",
    }}
    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#3d4066"}
    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#2B2E48"}
  >
    <FontAwesomeIcon 
      icon={isVisible ? faTimes : faBars} 
      style={{ fontSize: "16px" }} 
    />
  </button>

</div>
  );
};

export default Toolbox;