import React, { useState, useEffect, useRef, Children } from "react";
import { Bar, Pie, Line, Radar } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
  RadialLinearScale,
} from "chart.js";
import axios from "axios";
import styled ,{ keyframes } from "styled-components";
import { BorderBottomOutlined, SearchOutlined,MailOutlined,
  CheckCircleOutlined,
  UserOutlined,
  RiseOutlined,
  SmileOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  LineChartOutlined, } from "@ant-design/icons";
  import { Search, Eye, AlertCircle } from 'lucide-react';

import { Button, Card, Col, Input, Row, Space, Table, Tag, Modal } from "antd";
import Statistic from "antd/es/statistic/Statistic";
import Highlighter from "react-highlight-words";
import BreadCrumbs from "../BreadCrumbs";
import { render } from "@testing-library/react";
import GrafanaModal from "./Grafana";
import DropdownButton from "./Grafana";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
  RadialLinearScale
);

// Styled Components
const AnalyserWrapper = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color:white;
  &:hover {
    background-color: rgba(249, 250, 251, 0.8);
  }


`;

const AnalyserContainer = styled.div`
  // border: 1px solid rgb(43, 46, 74);
  border-radius: 8px;
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  box-sizing: border-box;
 box-shadow: 0 4px 6px -1px rgba(43, 46, 74, 0.1),
              0 2px 4px -1px rgba(43, 46, 74, 0.06);
  
  /* Smooth transitions for all properties */
  transition: all 0.3s ease-in-out;
  
  /* Enhance on hover */
  &:hover {
    border-color: rgba(43, 46, 74, 0.8);
    box-shadow: 0 8px 12px -1px rgba(43, 46, 74, 0.12),
                0 4px 6px -1px rgba(43, 46, 74, 0.08);
    transform: translateY(-1px);
  }
  
  /* Add subtle gradient background */
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 1),
    rgba(249, 250, 251, 0.8)
  );
  
  /* Optional: Add a subtle border glow on focus-within */
  &:focus-within {
    border-color: rgb(43, 46, 74);
    box-shadow: 0 0 0 3px rgba(43, 46, 74, 0.1);
  }
  
  /* Smooth content appearance */
  & > * {
    transition: opacity 0.2s ease-in-out;
  }
  
  /* Optional: Add responsive padding */
  @media (max-width: 768px) {
    padding: 16px;
  }



`;
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-8px); }
  to { opacity: 1; transform: translateY(0); }
`;
const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
`;

const shimmer = keyframes`
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
`;

const floatUp = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-4px); }
  100% { transform: translateY(0); }
`;

const containerStyle = {
  padding: "2rem",
  backgroundColor: "#f5f7fa",
  minHeight: "100vh"
};


const SelectContainer = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;

  /* Label animation and styling */
  label {
    font-size: 0.9rem;
    font-weight: 500;
    color: rgb(43, 46, 74);
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    padding-left: 2px;

    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 0;
      height: 2px;
      background: rgb(79, 83, 124);
      transition: width 0.3s ease;
    }

    &:hover {
      color: rgb(79, 83, 124);
      transform: translateX(4px);
      
      &::after {
        width: 100%;
      }
    }
  }

  /* Enhanced select styling */
  select {
    padding: 10px 12px;
    border-radius: 8px;
    border: 2px solid rgba(43, 46, 74, 0.2);
    background-color: white;
    font-size: 0.95rem;
    color: rgb(43, 46, 74);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    
    /* Custom dropdown arrow with animation */
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(43, 46, 74)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 16px;
    padding-right: 40px;
    border-radius:10px;

    /* Active state animation */
    &:active {
      transform: scale(0.98);
    }

    /* Hover state with floating animation */
    &:hover {
      border-color: rgb(43, 46, 74);
      transform: translateY(-2px);
      box-shadow: 0 8px 16px rgba(43, 46, 74, 0.1);
      // animation: ${floatUp} 2s ease-in-out infinite;
    }

    /* Focus state with pulse */
    &:focus {
      outline: none;
      border-color: rgb(43, 46, 74);
      box-shadow: 0 0 0 3px rgba(43, 46, 74, 0.1);
      // animation: ${pulse} 2s infinite;
    }

    /* Enhanced option styling */
    option {
      padding: 10px;
      font-size: 0.95rem;
      transition: all 0.2s ease;
      border-radius: 25px;
      
      &:hover {
        background-color: rgba(43, 46, 74, 0.1);
      }
    }

    /* Empty option styling with shimmer effect */
    option:first-child {
      color: rgb(156, 163, 175);
      background: linear-gradient(
        90deg,
        rgba(43, 46, 74, 0.05),
        rgba(43, 46, 74, 0.1),
        rgba(43, 46, 74, 0.05)
      );
      background-size: 1000px 100%;
      animation: ${shimmer} 3s infinite linear;
    }
  }

  /* Interactive loading state */
  ${props => props.loading && `
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 8px;
      z-index: 1;
    }
  `}

  /* Error state animation */
  ${props => props.error && `
    select {
      border-color: #ef4444;
      animation: shake 0.5s linear;
    }

    @keyframes shake {
      0%, 100% { transform: translateX(0); }
      25% { transform: translateX(-8px); }
      75% { transform: translateX(8px); }
    }
  `}

  /* Success state animation */
  ${props => props.success && `
    select {
      border-color: #10b981;
      &::after {
        content: '✓';
        position: absolute;
        right: 40px;
        color: #10b981;
        animation: fadeIn 0.3s ease;
      }
    }
  `}
`;

const ChartsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;
`;

const ChartContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const LoadingSpinner = styled.div`
  align-self: center;
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid rgb(43, 46, 74);
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ErrorMessage = styled.div`
  color: #ef4444;
  font-size: 0.875rem;
  margin-top: 8px;
  padding: 12px 16px;
  background-color: #fee2e2;
  border-radius: 6px;
  border-left: 3px solid #ef4444;
  animation: slideIn 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  
  &::before {
    content: '⚠️';
    font-size: 1rem;
  }

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-10px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;
const cardStyle = {
  borderRadius: "12px",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
  border: "1px solid #e1e4e8",
  marginBottom:'10px',
  marginTop:"10px"
  
};

const headerStyle = {
 // position: 'relative',
 marginBottom :'70px',
  textAlign: 'center',
  fontSize: '1rem',
  fontWeight: '500', // Extra bold
  color: '#black', // White text
  letterSpacing: '1px',
  padding: '10px 0',
  margin: '0 0 2.5rem 0',
  //fontFamily: "'Inter', 'Roboto', sans-serif",
  
  // Professional background with depth
  // background: '#5046e5d9',
  borderRadius: '10px',
 // boxShadow: '0 10px 25px rgba(0, 0, 0, 0.1), 0 6px 10px rgba(0, 0, 0, 0.08)',
  
  // Text refinement
  textTransform: 'uppercase',
  
  // Modern accent effects
  position: 'relative',
  overflow: 'hidden',
  
  // Pseudo-element for additional refinement
  // '&::before': {
  //   content: '""',
  //   position: 'absolute',
  //   top: '-50%',
  //   left: '-50%',
  //   width: '200%',
  //   height: '200%',
  //   background: 'linear-gradient(45deg, transparent, rgba(255,255,255,0.05), transparent)',
  //   transform: 'rotate(-45deg)',
  //   zIndex: 1
  // },
  
 
  // '&::after': {
  //   content: '""',
  //   position: 'absolute',
  //   bottom: '0',
  //   left: '50%',
  //   transform: 'translateX(-50%)',
  //   width: '100px',
  //   height: '4px',
  //   backgroundColor: '#3498db',
  //   borderRadius: '2px'
  // }
};
const selectContainerStyle = {
  marginBottom: "2rem",
  padding: "1.0rem",
  backgroundColor: "white",
  borderRadius: "10px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.05)"
};
const selectStyle = {
  width: "60%",
  padding: "0.8rem",
  borderRadius: "6px",
  border: "1px solid #e1e4e8",
  fontSize: "1rem",
  color: "#2b2e4a",
  marginTop: "0.5rem",
  marginLeft:"25px",
};
const getOptionColor = (index) => {
  const colors = ['#FFDDC1', '#FFABAB', '#FFC3A0', '#FF677D', '#D4A5A5', '#392F5A'];
  return colors[index % colors.length]; // Cycle through colors
};


const statisticStyle = {
  fontSize: "1.2rem",
  fontWeight: "600",
  color: "#2b2e4a"
};


const titleStyle = {
  fontSize: "1.1rem",
  color: "#2b2e4a",
  marginBottom: "0.5rem",
  display: "flex",
  alignItems: "center",
  gap: "8px"
};

const iconStyle = {
  fontSize: "1.2rem",
  color: "blue"
};


const StatsCard = ({ title, value, icon, bgColor }) => (
  <div className="flex-1 min-w-[250px]">
    <div className={`${bgColor} rounded-lg p-4 h-full transition-all duration-200 hover:shadow-md`}>
      <div className="flex items-start justify-between">
        <div>
          <p className="text-sm font-medium text-gray-500 mb-1">{title}</p>
          <h3 className="text-xl font-bold text-gray-800 break-words">{value}</h3>
        </div>
        <div className="p-2 rounded-lg bg-white shadow-sm">
          {icon}
        </div>
      </div>
    </div>
  </div>
);
const StatisticCard = ({ icon: Icon, title, value, className = '' }) => (
  <div className="p-4 rounded-lg bg-white/50 backdrop-blur-sm hover:bg-white/60 transition-all duration-300">
    <div className="flex items-center gap-2 mb-2">
      <Icon className="w-5 h-5 text-blue-600" />
      <h3 className="text-sm font-medium text-gray-600">{title}</h3>
    </div>
    <p className="text-2xl font-semibold text-gray-900">{value}</p>
  </div>
);

const SurveyAnalyser = (props) => {
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const [formTitles, setFormTitles] = useState([]);
  const [formId, setFormId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [chartData, setChartData] = useState(null);
  const [pieChartData, setPieChartData] = useState(null);
  const [color, setColor] = useState("");
  const [analyser, setAnalyser] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [imageAnalyser, setImageAnalyser] = useState("");
  const [videoAnalyser, setVideoAnalyser] = useState("");
  const [audioAnalyser, setAudioAnalyser] = useState("");
  const [formAnalysisData, setFormAnalysisData] = useState(null);
  const [isIndividual, setIsIndividual] = useState(false);
  const [selectedAnalysis, setSelectedAnalysis] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [showGrafanaButton, setShowGrafanaButton] = useState(false);
  const [showPromptInput, setShowPromptInput] = useState(false);
const [prompt, setPrompt] = useState('');
  const [chartLabels, setChartLabels] = useState([
    "Angry",
    "Disgust",
    "Fear",
    "Happy",
    "Sad",
  ]);
  const [chartBackground, setChartBackground] = useState([
    "#4caf50",
    "#f44336",
    "#ff9800",
    "#9c27b0",
    "#03a9f4",
  ]);
  const [pieChartBackground, setPieChartBackground] = useState([
    "#4caf50",
    "#8BC34A",
    "#f44336",
    "#673AB7",
    "#ff9800",
    "#FFC107",
    "#9c27b0",
    "#009688",
    "#03A9F4",
    "#E6E6FA",
  ]);
  const [sentiment, setSentiment] = useState("");
  const [audioLength, setAudioLength] = useState("");

  const [allKPIData, setAllKPIData] = useState([]);
  const [videoKPIData, setVideoKPIData] = useState([]);
  const [imageKPIData, setImageKPIData] = useState([]);
  const [audioKPIData, setAudioKPIData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    filterDropdownProps: {
      onOpenChange(open) {
        if (open) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "",
      dataIndex: "no",
      key: "no",
      onHeaderCell: () => ({
        style: {
          fontWeight: 'bold',
          color: 'black',
        },
      }),
    },
    {
      title: <span style={{ color: 'black', fontWeight: 'bold' }}>Respondent</span>, 
      dataIndex: "respondent",
      key: "respondent",
      width: "20%",
      ...getColumnSearchProps("respondent"),
      onHeaderCell: () => ({
        style: {
          fontWeight: 'bold',
          color: 'black',
        },
      }),
    },
    {
      title: <span style={{ color: 'black', fontWeight: 'bold' }}>Status</span>,
      dataIndex: "status",
      key: "status",
      onHeaderCell: () => ({
        style: {
          fontWeight: 'bold',
          color: 'black',
        },
      }),
      render: (text) => <span style={{ color: text === 'Completed' ? 'green' : 'green' }}>{text}</span>,
    },
    {
      title: <span style={{ color: 'black', fontWeight: 'bold' }}>Submission Time</span>,
      dataIndex: "submission",
      key: "submission",
      onHeaderCell: () => ({
        style: {
          fontWeight: 'bold',
          color: 'black',
        },
      }),
    },
    {
      title:<span style={{ color: 'black', fontWeight: 'bold' }}>Sentiments</span>,
      onHeaderCell: () => ({
        style: {
          fontWeight: 'bold',
          color: 'black',
        },
      }),
      children: [
        {
          title:<span style={{ color: 'black', fontWeight: 'bold' }}>Audio</span>,
          dataIndex: "audio",
          key: "audio",
          onHeaderCell: () => ({
            style: {
              fontWeight: 'bold',
              color: 'black',
            },
          }),
          render: (text) => (
            <>
              {text
                ? text.map((tag) => (
                    <Tag
                      color={
                        tag === "positive"
                          ? "green"
                          : tag === "neutral"
                          ? "yellow"
                          : "red"
                      }
                      key={tag}
                      style={{ margin: '4px', borderRadius: '8px' }}
                    >
                      {tag.toUpperCase()}
                    </Tag>
                  ))
                : "N/A"}
            </>
          ),
        },
        {
          title: <span style={{ color: 'black', fontWeight: 'bold' }}>Image</span>,
          dataIndex: "image",
          key: "image",
          onHeaderCell: () => ({
            style: {
              fontWeight: 'bold',
              color: 'black',
            },
          }),
          render: (text) => (
            <>
              {text
                ? text.map((tag) => (
                    <Tag
                      color={
                        tag === "positive"
                          ? "green"
                          : tag === "neutral"
                          ? "yellow"
                          : "red"
                      }
                      key={tag}
                      style={{ margin: '4px', borderRadius: '8px' }}
                    >
                      {tag.toUpperCase()}
                    </Tag>
                  ))
                : "N/A"}
            </>
          ),
        },
        {
          title:<span style={{ color: 'black', fontWeight: 'bold' }}>Video</span>,
          dataIndex: "video",
          key: "video",
          onHeaderCell: () => ({
            style: {
              fontWeight: 'bold',
              color: 'black',
            },
          }),
          render: (text) => (
            <>
              {text
                ? text.map((tag) => (
                    <Tag
                      color={
                        tag === "positive"
                          ? "green"
                          : tag === "neutral"
                          ? "yellow"
                          : "red"
                      }
                      key={tag}
                      style={{ margin: '4px', borderRadius: '8px' }}
                    >
                      {tag.toUpperCase()}
                    </Tag>
                  ))
                : "N/A"}
            </>
          ),
        },
      ],
    },
    {
      title: <span style={{ color: 'black', fontWeight: 'bold' }}>View Analysis</span>,
      dataIndex: "view",
      key: "view",
      onHeaderCell: () => ({
        style: {
          fontWeight: 'bold',
          color: 'black',
        },
      }),
      render: (_, record) => (
        <>
          {record.image || record.video || record.audio ? (
            <a
              onClick={() =>
                navigate("/survey-analyser/view-analyser", {
                  state: { email: record.key, submitedBy: record.respondent },
                })
              }
              style={{
                color: '#1890ff',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              Click to view analysis
            </a>
          ) : (
            "N/A"
          )}
        </>
      ),
    },
  ];
  
  // Table component
  const MyTable = ({ data }) => {
    return (
      <Table
        columns={columns}
        dataSource={data}
        rowKey="key" // Ensure you have a unique key for each row
        pagination={{ pageSize: 10 }} // Pagination for better UX
        bordered
        style={{ backgroundColor: '#F8F8F8' }}
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-even' : 'table-row-odd')} // Alternate row colors
        on Click={() => console.log('Row clicked!')} // Optional row click handler
      />
    );
  };
  
  // CSS styles for the table
  const styles = {
    '.table-row-even': {
      backgroundColor: '#F8F8F8', // Light background for even rows
    },
    '.table-row-odd': {
      backgroundColor: '#F8F8F8', // White background for odd rows
    },
  };
  
  // Apply styles globally or in a CSS module
  Object.keys(styles).forEach((selector) => {
    const style = document.createElement('style');
    style.innerHTML = `${selector} { ${Object.entries(styles[selector]).map(([key, value]) => `${key}: ${value};`).join(' ')} }`;
    document.head.appendChild(style);
  })

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    console.log("submitted before:", submitted);
    setSubmitted((submit) => !submit);
    console.log("submitted After:", submitted);
    fetchSurveyForm();
  }, []);

  async function fetchSurveyForm() {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(
        "https://qyow74zw35.execute-api.ap-south-1.amazonaws.com/dev/survey-form?id=null"
      );
      setFormTitles(response.data);
    } catch (error) {
      console.error("Error fetching survey form:", error?.response?.data);
      setError("Failed to fetch survey forms", error?.response?.data);
    } finally {
      setLoading(false);
    }
  }

  const handleTitleSelect = async (id) => {
    setFormTitle(formTitles?.find((forms) => forms.id === id)?.title);
    setSelectedTitle(id);
    setSelectedEmail("");
    setShowGrafanaButton(false);
    setChartData(null);
    setFormAnalysisData("");
    setIsIndividual(false);
    setSelectedAnalysis("");
    setImageAnalyser("");
    setVideoAnalyser("");
    setAudioAnalyser("");
    setImageKPIData([]);
    setVideoKPIData([]);
    setAudioKPIData([]);
    let emails = null;
    if (id) {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          `https://qyow74zw35.execute-api.ap-south-1.amazonaws.com/dev/form-users?form_id=${id}`
        );
        console.log("response.data:", response.data);
        emails = response.data;
        setEmailList(response.data);

        // data = { formId: id };
      } catch (error) {
        console.error("Error fetching survey form:", error?.response?.data);
        setError("Failed to fetch survey forms :" + error?.response?.data);
      } finally {
        setLoading(false);
      }
      try {
        setLoading(true);
        let config = {
          method: "post",
          url: "https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/survey-response/get-response-rate",
          data: { formId: id },
        };
        axios
          .request(config)
          .then((response) => {
            setFormAnalysisData(response.data.body);
          })
          .catch((error) => {
            setLoading(false);

            console.log(error);
          });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching survey form:", error?.response?.data);
        setError("Failed to fetch survey forms", error?.response?.data);
      }
      try {
        setLoading(true);
        const response = await axios.get(
          `https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/survey-analysis`
        );
        let data = response?.data?.body;
        setLoading(false);
        console.log("Data:", data);
        getTableData(data, emails);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching survey form:", error);
        setError("Failed to fetch survey forms", error?.response?.data);
      }
    }
  };
  function getTableData(formAllData, emails) {
    let data = [];

    console.log("emailList:", emails);
    emails?.map((email, index) => {
      let formReponse = formAllData?.find((forms) => forms.id === email.id);
      let object = {};
      object.no = index + 1;
      object.key = email.id;
      object.respondent = email.shared_with;

      formReponse?.status == "pending"
        ? (object.status = "INCOMPLETE")
        : (object.status = "COMPLETED");
      if (formReponse.image_kpi) {
        let sentiments = [];
        formReponse.image_kpi.map((data) => {
          sentiments.push(data.overall_sentiment);
        });
        object.image = sentiments;
      }
      if (formReponse.video_kpi) {
        let sentiments = [];
        formReponse.video_kpi.map((data) => {
          sentiments.push(data.overall_sentiment);
        });
        object.video = sentiments;
      }
      if (formReponse.audio_kpi) {
        let sentiments = [];
        formReponse.audio_kpi.map((data) => {
          sentiments.push(data.overall_sentiment);
        });
        object.audio = sentiments;
      }
      object.submission = formReponse.formattedDuration;
      data.push(object);
    });
    console.log("emails:", data);
    setTableData(data);
  }
  setTimeout(() => {
    setShowGrafanaButton(true);
  }, 5000);
  const handleEmailSelect = async (email) => {
    setChartData(null);
    setAnalyser("");
    setImageKPIData([]);
    setVideoKPIData([]);
    setAudioKPIData([]);
    setImageAnalyser("");
    setVideoAnalyser("");
    setAudioAnalyser("");
    console.log("email.id:", email);
    let filterData = {};
    let transformedData;
    setSelectedEmail(email);
    if (email) {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          `https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/survey-analysis`
        );
        // console.log("response.data:", response.data);
        let data = response?.data?.body;
        filterData = data.filter((body) => body.id === email);
        console.log("allKPIData:", filterData);
        // transformedData = filterData.map((item) => ({
        //   ...item,
        //   image_kpi: item.image_kpi
        //     ? Object.keys(item.image_kpi).map((fileName) => ({
        //         filename: fileName,
        //         ...item.image_kpi[fileName],
        //       }))
        //     : null,
        //   audio_kpi: item.audio_kpi
        //     ? Object.keys(item.audio_kpi).map((fileName) => ({
        //         filename: fileName,
        //         ...item.audio_kpi[fileName],
        //       }))
        //     : null,
        //   video_kpi: item.video_kpi
        //     ? Object.keys(item.video_kpi).map((fileName) => ({
        //         filename: fileName,
        //         ...item.video_kpi[fileName],
        //       }))
        //     : null,
        // }));
        setAllKPIData(filterData[0]);
      } catch (error) {
        console.error("Error fetching survey form:", error?.response?.data);
        setError("Failed to fetch survey forms", error?.response?.data);
      } finally {
        setLoading(false);
      }
      setLoading(true);
      setError("");
      try {
        console.log("transformedData[0]:", transformedData[0]);
        if (transformedData[0].image_kpi) {
          setImageAnalyser("Image");
          setImageKPIData(transformedData[0].image_kpi);
          console.log("image_kpi:", transformedData[0].image_kpi);
        }
        if (transformedData[0].audio_kpi) {
          setAudioAnalyser("Audio");
          setAudioKPIData(transformedData[0].audio_kpi);
          console.log("audio_kpi:", transformedData[0].audio_kpi);
        }
        if (transformedData[0].video_kpi) {
          setVideoAnalyser("Video");
          setVideoKPIData(transformedData[0].video_kpi);

          // console.log("---------line 301 ");
        }
        // const sentimentData = videoKPIdata.average_emotion_scores;
      } catch (error) {
        console.error("Error fetching sentiment data:", error);
        setError("Error fetching sentiment data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const getChartData = (data) => {
    return [
      data.average_emotion_scores?.angry,
      data.average_emotion_scores?.disgust,
      data.average_emotion_scores?.fear,
      data.average_emotion_scores?.happy,
      data.average_emotion_scores?.sad,
    ];
  };
  const getPieChartData = (data) => {
    return (data = Object.values(data.frame_analysis).map(
      (frame) => frame.emotion_score
    ));
  };
  const getPieChartLabels = (data) => {
    const emotionArray = Object.keys(data.frame_analysis).map((frame) => {
      const { dominant_emotion } = data.frame_analysis[frame];
      return `${frame} ${dominant_emotion}`;
    });
    return emotionArray;
  };

  const getChartOptions = (title) => ({
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: {
        display: true,
        text: title,
        font: { size: 16 },
      },
    },
  });

  const handleViewAnalysis = (title) => {
    setLoading(true);
    setImageKPIData([]);
    setVideoKPIData([]);
    setAudioKPIData([]);
    setImageAnalyser("");
    setVideoAnalyser("");
    setAudioAnalyser("");
    setSelectedEmail("");

    setInterval(() => {
      setLoading(false);
    }, 2000);

    setSelectedAnalysis(title);
    if (title === "Individual Analysis") {
      setIsIndividual(true);
    } else {
      setIsIndividual(false);
    }
  };

  const handlePromptToggle = (isOpen) => {
    setIsPromptOpen(isOpen);
  };
  return (
    <>
    <div style={{ ...containerStyle, padding: "20px",
       backgroundColor: "#ffffff",
        height: "100vh", overflow: "hidden", position: "fixed", width: "-webkit-fill-available",paddingBottom: "100px" }}>
      <BreadCrumbs location={props.location} />
  
        
      <div style={{ maxWidth: "1400px", margin: "0 auto", height: "100%", overflowY: "auto", scrollbarWidth: "none", msOverflowStyle: "none", filter: isPromptOpen ? 'blur(5px)' : 'none', transition: 'filter 0.3s ease' }}>
          {/* Hide scrollbar for WebKit browsers */}
          <style>
            {`
              div::-webkit-scrollbar {
                display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
              }
            `}
          </style>
  
        {/* <div style={{ marginTop: "20px", textAlign: "center" }}>
          <h2 style={{ ...headerStyle, color: "#2C3E50", fontWeight: "700", fontSize: "2.5rem" }}>Survey Analyser</h2>
        </div> */}
  
        <div style={{  }}>
      <div style={{ ...selectContainerStyle, marginBottom: "20px", display: "flex", justifyContent: "center", alignItems: "center", }}>
        <label htmlFor="form-title-select" style={{ 
          fontSize: "1.2rem", 
          fontWeight: "600", 
          color: "#2C3E50",
          marginRight: "10px"
        }}>
          Select a Form:
        </label>
  
        <select
          id="form-title-select"
          value={selectedTitle}
          onChange={(e) => handleTitleSelect(e.target.value)}
          style={{
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #BDC3C7",
            width: "300px",
            fontSize: "1rem",
            backgroundColor: "#ffffff",
            transition: "border 0.3s ease",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <option value="">-- Select a Form --</option>
          {formTitles.map((formData, index) => (
            <option key={index} value={formData.id}>
              {formData.title}
            </option>
          ))}
        </select>
  
        {selectedTitle && formAnalysisData && !loading && !error && (
          <div style={{ marginLeft: "20px" }}>
            <DropdownButton onPromptToggle={handlePromptToggle} />
          </div>
        )}
      </div>
    </div>
  
        {error && (
          <div style={{
            padding: "1rem",
            backgroundColor: "#FDEBD0",
            border: "1px solid #F5B041",
            borderRadius: "8px",
            color: "#C0392B",
            marginBottom: "2rem",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
          }}>
            {error}
          </div>
        )}
  
        {loading && (
          <div style={{
            textAlign: "center",
            padding: "2rem",
            backgroundColor: "white",
            borderRadius: "10px",
            marginBottom: "2rem",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
            
          }}>
            <LoadingSpinner />
            <div style={{ color: "#2C3E50", fontWeight: "500", marginTop: "1rem" }}>
              Loading...
            </div>
          </div>
        )}
  
        {formAnalysisData && !loading && !error && (
          <>
            <Card title="Overall Form Analysis" style={{
              ...cardStyle,
              margin: "1rem 0",
              padding: "1.5rem",
              borderRadius: "10px",
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#ffffff",
              
            }}>
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={12} md={6}>
                  <Statistic
                    title={<span style={titleStyle}><MailOutlined style={iconStyle} /> Form Title</span>}
                    value={formTitle}
                    valueStyle={statisticStyle}
                  />
                </Col>
  
                <Col xs={24} sm={12} md={6}>
                  <Statistic
                    title={<span style={titleStyle}><CheckCircleOutlined style={iconStyle} /> Total Sent</span>}
                    value={formAnalysisData.totalSent}
                    valueStyle={statisticStyle}
                  />
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Statistic
                    title={<span style={titleStyle}><UserOutlined style={iconStyle} /> Total Responses</span>}
                    value={formAnalysisData.totalResponses}
                    valueStyle={statisticStyle}
                  />
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Statistic
                    title={<span style={titleStyle}><RiseOutlined style={iconStyle} /> Response Rate</span>}
                    value={formAnalysisData.responseRate}
                    valueStyle={statisticStyle}
                  />
                </Col>
              </Row>
              <Row gutter={[24, 24]} style={{ marginTop: "2rem" }}>
                <Col xs={24} sm={12} md={6}>
                  <Statistic
                    title={<span style={titleStyle}><SmileOutlined style={iconStyle} /> Aggregate Sentiment</span>}
                    value={formAnalysisData.aggregateSentiment?.toUpperCase()}
                    valueStyle={{
                      ...statisticStyle,
                      color: formAnalysisData.aggregateSentiment?.toLowerCase() === 'positive' ? '#52c41a' :
                        formAnalysisData.aggregateSentiment?.toLowerCase() === 'negative' ? '#f5222d' :
                        formAnalysisData.aggregateSentiment?.toLowerCase() === 'neutral' ? '#faad14' :
                        statisticStyle.color
                    }}
                  />
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Statistic
                    title={<span style={titleStyle}><ClockCircleOutlined style={iconStyle} /> Average Completion Time</span>}
                    value={formAnalysisData.avgCompletionTime}
                    valueStyle={statisticStyle}
                  />
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Statistic
                    title={<span style={titleStyle}><ExclamationCircleOutlined style={iconStyle} /> Survey Abandonment Rate</span>}
                    value={formAnalysisData.surveyAbandonmentRate}
                    valueStyle={statisticStyle}
                  />
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Statistic
                    title={<span style={titleStyle}><LineChartOutlined style={iconStyle} /> Breakdown Completion Rate</span>}
                    value={formAnalysisData.breakdownCompletionRate}
                    valueStyle={statisticStyle}
                  />
                </Col>
              </Row>
            </Card>
  
            <Card style={{
              ...cardStyle,
              backgroundColor: "#ffffff",
              padding: "1.5rem",
              borderRadius: "10px",
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)"
            }}>
              <Table 
                columns={columns} 
                dataSource={tableData}
                pagination={{ 
                  pageSize: 10,
                  position: ["bottomCenter"],
                  style: { marginTop: "1rem" }
                }}
                scroll={{ x: true }}
              />
            </Card>
          </>
        )}
      </div>
    </div>
  </>
  );
};

export default SurveyAnalyser;
