import React, { useState } from "react";
import { Layout } from "antd";
import HeaderAdmin from "../layouts/HeaderAdmin";
import SiderMenu from "./SiderMenu";
import moment from "moment";
import { routes } from "../../util/constants";
import { useLocation } from "react-router-dom";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { msalConfig } from "../../authConfig";
import { InteractionType } from "@azure/msal-browser";
import Home from "../Home";
import FormBuilder from "../survey-form-creation/FormBuilder";
import ViewForms from "../survey-form-creation/ViewForm";
import SurveyAnalyser from "../survey-form-creation/surveyAnalyser";
import ViewAnalysis from "../survey-form-creation/viewAnalysis";

const { Sider, Content, Footer } = Layout;

export default function AdminDashboard(props) {
  const { instance } = useMsal();
  const [collapsed, setCollapsed] = useState(false);
  const [sideCollapsed, setSideCollapsed] = useState(false);
  const [viewToggleBtn, setViewToggleBtn] = useState(true);
  const location = useLocation();
  const purpleColor = "#5046e5d9";
  const customFont = "'Calibri', sans-serif";

  const toggle = () => {
    setCollapsed(!collapsed);

    setSideCollapsed(false);
  };
  const handleMouseEnter = () => {
    setViewToggleBtn(true);
  };
  const handleMouseLeave = () => {
    setViewToggleBtn(false);
  };
  const { pathname } = props.location;
  function renderPathName(pathname) {
    switch (pathname) {
      case routes.FORM_BUILDER:
        return <FormBuilder location={location} />;
      case routes.VIEW_FORM:
        return <ViewForms location={location} />;
      case routes.SURVEY_ANALYSER:
        return <SurveyAnalyser location={location} />;
      case routes.VIEW_ANALYSER:
        return <ViewAnalysis location={location} />;
      default:
        return <Home location={location} />;
    }
  }
  const authRequest = {
    ...msalConfig.auth.scopes,
  };
  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
      >
        <Layout className="admin-dash ">
          <Sider
            width={260}
            breakpoint="sm"
            collapsedWidth={sideCollapsed ? "80" : "0"}
            theme="light"
            
            trigger={null}
            collapsible
            collapsed={sideCollapsed ? sideCollapsed : collapsed}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className={collapsed ? "display-switch" : ""}>
              <div className={!sideCollapsed ? "toogle-off" : "toogle-on"}>
                <span
                  className={
                    sideCollapsed && !viewToggleBtn
                      ? "collapsed-switch-visible"
                      : ""
                  }
                ></span>
              </div>
            </div>
            <SiderMenu 
              history={props.history}
              user={props.user}
              collapsed={collapsed}
            ></SiderMenu>
          </Sider >
          <Layout className="site-layout">
            <HeaderAdmin
              toggle={toggle}
              collapsed={collapsed}
              user={instance.getActiveAccount()}
              signOut={props.signOut}
            />

            <Content
              style={{
                margin: "10px 10px",
                minHeight: "87vh",
              }}
            >
              {renderPathName(pathname)}
            </Content>
            <footer
  style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(80 70 229)", 
    padding: "10px 0",
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    fontFamily: customFont,
    zIndex: 998,
    height: "30px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.5)", // Adding shadow for depth
  }}
>
        <div style={{ fontSize: ".8rem" }}>
          <h3 style={{ color: "white" }}> &#169; 2025 RRT Team </h3>
        </div>
      </footer>
          </Layout>
        </Layout>
      </MsalAuthenticationTemplate>
    </>
  );
}
