import React, { useState, useEffect, useRef } from "react";
import * as XLSX from "xlsx";
import { serialize } from "bson";
import { saveAs } from "file-saver";
import { useDrop } from "react-dnd";
import { v4 as uuidv4 } from "uuid";
import Toolbox from "./ToolBox";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import FormField from "./FormField";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faCopy,
  faSave,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import TextArea from "antd/es/input/TextArea";
import styled from "styled-components";
import {
  PlusOutlined,
  SendOutlined,
  AliwangwangOutlined,
  RobotOutlined,
  CloseOutlined,
  AliwangwangFilled,
  WechatWorkFilled,
  WechatWorkOutlined,
} from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import axios from "axios";
import BreadCrumbs from "../BreadCrumbs";
import { UploadOutlined } from "@ant-design/icons";
import { message } from "antd";

const FormBuilder = (props) => {
  const fileInputRef = useRef(null);
  const [formItems, setFormItems] = useState([]);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [rotate, setRotate] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const [formTitle, setFormTitle] = useState("");
  const [chatbotInput, setChatbotInput] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [scrollOffSet, setScrollOffSet] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [isToolboxOpen, setIsToolboxOpen] = useState(true);
  const [toolboxHeight, setToolboxHeight] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const handleToolboxToggle = (isOpen) => {
    setIsToolboxOpen(isOpen);
    setToolboxHeight(isOpen ? 300 : 0);
  };
  // const { createForm } = useCreateForm();

  const navigate = useNavigate();
  const [, drop] = useDrop(() => ({
    accept: [
      "text",
      "checkbox",
      "radio",
      "dropdown",
      "date",
      "textarea",
      "email",
      "number",
      "password",
      "file",
      "button",
    ],
    drop: (item) => {
      addItemToForm(item);
    },
  }));
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const LoadingSpinner = styled.div`
    align-self: center;
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid rgb(43, 46, 74);
    border-radius: 50%;
    animation: spin 1s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrollOffSet(offset);
      setIsScrolled(offset > 10); // Adjust threshold as needed
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const FormContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  `;

  const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 24px;

    .ant-form-item {
      margin-bottom: 0;
    }
  `;

  const FieldsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 24px;
  `;

  const EmptyState = styled.div`
    text-align: center;
    padding: 40px;
    background: #f8fafc;
    border-radius: 12px;
    border: 2px dashed #e2e8f0;
    color: #64748b;
    font-size: 16px;
    margin: 20px 0;
  `;

  const FormFieldWrapper = styled.div`
    background: white;
    border-radius: 12px;
    border: 1px solid #e5e7eb;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: rgb(43, 46, 74);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
      transform: translateY(-2px);
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      background: rgb(43, 46, 74);
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:hover:before {
      opacity: 1;
    }
  `;
  const ChatModal = styled.div`
    position: fixed;
    bottom: 70px;
    right: 450px;
    width: 400px;
    height: 500px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    z-index: 1000;
    border: 1px solid #e8e8e8;
    transition: all 0.3s ease;
  `;

  const ChatHeader = styled.div`
    padding: 15px 20px;
    background: rgb(43, 46, 72);
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;

  const ChatArea = styled.div`
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    background: #f9f9f9;
    display: flex;
    flex-direction: column;
  `;
  const TypingIndicatorContainer = styled.div`
    display: flex;
    align-items: center;
    background: white;
    padding: 12px;
    border-radius: 10px;
    width: fit-content;
    margin-top: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  `;
  const TypingDot = styled.span`
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: rgb(43, 46, 72);
    border-radius: 50%;
    margin: 0 3px;
    opacity: 0.6;

    &:nth-child(1) {
      animation: typing 1.4s infinite;
    }
    &:nth-child(2) {
      animation: typing 1.4s infinite 0.2s;
    }
    &:nth-child(3) {
      animation: typing 1.4s infinite 0.4s;
    }

    @keyframes typing {
      0%,
      100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px);
      }
    }
  `;

  const WelcomeMessage = styled.div`
    padding: 10px 15px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  `;

  const ChatInputContainer = styled.div`
    padding: 15px;
    background: white;
    border-top: 1px solid #e8e8e8;
    display: flex;
    gap: 10px;
    align-items: center;
  `;

  const BouncingChatButton = styled(AliwangwangOutlined)`
    float: right;
    font-size: 45px;
    color: rgb(56, 68, 174);
    margin: -6px 150px 0px 0px;
  `;
  //new chatbot css
  // const ChatbotContainer = styled.div`
  //   position: fixed;
  //   bottom: 20px;
  //   right: 20px; // Adjusted for better positioning
  //   display: flex;
  //   align-items: flex-end;
  //   gap: 16px;
  //   z-index: 1000;
  //   transition: all 0.3s ease; // Smooth transition for the container
  // `;

  // const ChatInputContainer = styled.div`
  //   display: flex;
  //   align-items: center;
  //   background-color: white;
  //   border-radius: 12px;
  //   padding: 12px;
  //   box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  //   transform-origin: bottom right;
  //   opacity: ${props => props.isOpen ? 1 : 0};
  //   transform: ${props => props.isOpen ? 'translateY(0) scale(1)' : 'translateY(20px) scale(0.9)'};
  //   transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  //   width: ${props => props.isOpen ? '400px' : '0'};
  //   margin-right: 16px;
  //   border: 2px solid rgba(43, 46, 72, 0.1);

  //   &:hover {
  //     box-shadow: 0 12px 28px rgba(0, 0, 0, 0.2);
  //   }
  // `;

  // const StyledInput = styled(Input)`
  //   border: none;
  //   background: rgba(43, 46, 72, 0.05);
  //   border-radius: 8px;
  //   padding: 12px;
  //   margin-right: 12px;
  //   transition: all 0.2s ease;

  //   &:hover, &:focus {
  //     background: rgba(43, 46, 72, 0.08);
  //     box-shadow: none;
  //   }

  //   &::placeholder {
  //     color: rgba(43, 46, 72, 0.6);
  //   }
  // `;

  // const StyledSendButton = styled(Button)`
  //   background: none;
  //   border: none;
  //   padding: 8px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   cursor: pointer;
  //   transition: all 0.2s ease;

  //   &:hover {
  //     transform: scale(1.1);
  //   }

  //   .anticon {
  //     font-size: 24px;
  //     color: rgb(43, 46, 72);
  //   }
  // `;

  // const ChatbotIcon = styled(WechatFilled)`
  //   font-size: 50px;
  //   color: rgb(43, 46, 72);
  //   cursor: pointer;
  //   transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  //   transform: ${props => props.isChatbotOpen ? 'rotate(360deg) scale(1.1)' : 'rotate(0) scale(1)'};

  //   &:hover {
  //     transform: ${props => props.isChatbotOpen ? 'rotate(360deg) scale(1.2)' : 'scale(1.1)'};
  //     filter: brightness(1.1);
  //   }

  //   animation: ${props => props.isChatbotOpen ? 'none' : 'bounce 2s infinite'};

  //   @keyframes bounce {
  //     0%, 20%, 50%, 80%, 100% {
  //       transform: translateY(0);
  //     }
  //     40% {
  //       transform: translateY(-12px);
  //     }
  //     60% {
  //       transform: translateY(-6px);
  //     }
  //   }
  // `;

  const iconStyles = {
    // display: "flex",
    position: "sticky",
    bottom: 10,
    right: 145,
    cursor: "pointer",
    zIndex: 1,
    float: "right",
    transition: "all 0.2s ease",
    // display: flex;
    // alignItems: "center",
    // background-color: none;
    // border-radius: 6px;
    height: "36px",
    // marginRight: "30px",
    // border: " 1px solid rgb(43, 46, 72)",
    marginRight: "15.5%",
    marginLeft: " 1.25%",
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  };
  if (isScrolled) {
    if (scrollOffSet < 30) {
      iconStyles.bottom = iconStyles.bottom + scrollOffSet + 3;
    } else {
      iconStyles.bottom = iconStyles.bottom + 33;
    }
    iconStyles.transform = "scale(1)";
  }

  const handleChatBotInput = (e) => {
    setChatbotInput(e.target.value);
  };

  const chatbotSubmit = async () => {
    if (!chatbotInput.trim()) return;

    setFormLoading(true);
    // NEW: Add this line
    setIsTyping(true); // Show typing indicator
    let payload = { prompt: chatbotInput };

    try {
      const response = await axios.post(
        "https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/chatbot",
        payload,
        {
          "Content-Type": "application/json",
        }
      );

      const updatedFormSchema = response.data.body.message.form_schema.map(
        (field) => ({
          ...field,
          id: uuidv4(),
        })
      );

      setFormItems(updatedFormSchema);
      setPreviewMode(!previewMode);
      setChatbotInput("");
      setIsChatbotOpen(false);
    } catch (error) {
      toast.error("Sorry! Unexpected Error occurs");
    } finally {
      setIsTyping(false);
      setFormLoading(false);
      // NEW: Add this line
      // Hide typing indicator
    }
  };

  const viewCreatedForms = () => {
    navigate("/view-forms");
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const generateHtmlString = () => {
    return formItems
      .map((item) => {
        const placeholder = capitalizeFirstLetter(item.placeholder);
        switch (item.field_type) {
          case "text":
            return `<Form.Item label="${item.label}"labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} name="${item.label}">
                <Input placeholder="${item.placeholder}" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}/>
              </Form.Item>`;
          case "email":
            return `
              <Form.Item name="${item.label}" label="${item.label}">
                <Input placeholder="${item.placeholder}" />
              </Form.Item>`;
          case "number":
            return `
              <Form.Item label="${item.label}" name="${item.label}">
                <InputNumber
                  placeholder="${item.placeholder}"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>`;
          case "password":
            return `
              <Form.Item label="${item.label}" name="${item.label}">
                <Input.Password placeholder="${item.placeholder}" />
              </Form.Item>`;
          case "checkbox":
            return `
            <Form.Item label="${item.label}">
                <Checkbox.Group>
                  ${item.options
                    .map(
                      (option, index) =>
                        `<Checkbox value=${option}>${option}</Checkbox>`
                    )
                    .join("")}
                </Checkbox.Group>
              </Form.Item>
      `;
          case "radio":
            return `
              <Form.Item label="${item.label}">
                <Radio.Group>
                  ${item.options
                    .map(
                      (option, index) =>
                        ` <Radio value=${option}>${option} </Radio>`
                    )
                    .join("")}
                </Radio.Group>
              </Form.Item>`;
          case "dropdown":
            return `
              <Form.Item label="${item.label}" name="${item.label}">
                <Select
                  placeholder="${item.placeholder}"
                  filterSort=${(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                      .join("")}
                  options=${item.options
                    .map((option, index) => ({
                      value: option,
                      label: option,
                    }))
                    .join("")}
                />
              </Form.Item>`;
          case "date":
            return `
              <Form.Item label="${item.label}" name="${item.label}">
                <DatePicker />
              </Form.Item>`;
          case "textarea":
            return `
              <Form.Item label="${item.label}">
                <TextArea rows={4} placeholder="${item.placeholder}" />
              </Form.Item>`;
          case "file":
            // return <input type="file" disabled={true} />;
            return `
              <Form.Item
                label="${item.label}"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload action="/upload.do" listType="picture-card" maxCount={1}>
                  <button
                    style={{
                      border: 0,
                      background: "none",
                      
                    }}
                    type="button"
                  >
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 5,
                      }}
                    >
                      Upload
                    </div>
                  </button>
                </Upload>
              </Form.Item>`;
          case "button":
            return `
                    <Form.Item
                      wrapperCol={{
                        offset: 6,
                        span: 16,
                      }}
                    >
                      <Button type="primary" htmlType="submit">
                        ${placeholder}
                      </Button>
                    </Form.Item>`;
          default:
            return <div>Unknown Form Item</div>;
        }
      })
      .join("");
  };

  const handleCopyHtml = () => {
    const htmlString = generateHtmlString();
    navigator.clipboard.writeText(htmlString).then(() => {
      alert("HTML copied to clipboard!");
    });
  };
  const formTitleChange = (e) => {
    setFormTitle(e.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (chatbotInput.length > 0) {
        chatbotSubmit();
      }
    }
  };
  const addItemToForm = (item) => {
    const newItem = {
      id: uuidv4(),
      field_type: item.type,
      label: item.label,
      required: false,
      options:
        item.type === "dropdown" || "radio" || "checkbox"
          ? ["Option 1", "Option 2", "Option 3"]
          : [],

      placeholder: item.placeholder,
    };
    setFormItems((prevItems) => [...prevItems, newItem]);
  };

  const saveForm = async () => {
    if (!formTitle) {
      console.error("Please provide a title for your form.");
      toast.error("Please provide a title for your form.");
      // alert("Please provide a title for your form!");
      return; // Prevent saving if title is null
    }
    const payload = {
      title: formTitle,
      form_schema: formItems.map((item) => ({
        field_type: item.field_type,
        label: item.label,
        placeholder: item.placeholder,
        required: item.required,
        options: item.options,
      })),
    };

    try {
      console.log("payload.form_fields:", payload);
      // await createForm(payload.form_fields);
      const response = await axios.post(
        "https://qyow74zw35.execute-api.ap-south-1.amazonaws.com/dev/survey-form",
        payload,
        {
          "Content-Type": "application/json",
        }
      );
      console.log("response:", response);
      toast.success("Form and fields saved successfully");
      // alert("Form and fields saved successfully!");
    } catch (error) {
      toast.error("Error saving form.");
      // alert("Error saving form.");
    }
  };

  const convertExcelToBson = async (file) => {
    try {
      setFormLoading(true);
      setIsChatbotOpen(false);
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = async (e) => {
        try {
          const binaryStr = e.target.result;
          const workbook = XLSX.read(binaryStr, { type: "binary" });
          const sheet1 = workbook.Sheets[workbook.SheetNames[0]];

          console.log("Excel Data:", sheet1);

          const keys = Object.keys(sheet1);
          const values = keys.map((key) => sheet1[key]?.v).filter(Boolean);

          console.log("Extracted Excel Values:", values);

          const formattedValues = JSON.stringify(values).replace(/"/g, "'"); // Convert double quotes to single quotes
          const payload = { "Extracted Excel Values": formattedValues };

          console.log("Sending first API payload:", payload);

          const response = await fetch(
            "https://3bic2tzps3.execute-api.ap-south-1.amazonaws.com/dev/chatbotfile",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            }
          );

          if (!response.ok) {
            setFormLoading(false);
            setIsChatbotOpen(false);
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const result = await response.json();
          console.log("First API Response:", result);

          // Call second API with the first API's response
          sendExtractedData(result);
        } catch (apiError) {
          console.error("API Request Failed:", apiError);
        }
      };
    } catch (error) {
      console.error("Error converting Excel to BSON:", error);
    }
  };

  const sendExtractedData = async (extractedValues) => {
    
    try {
      const payload = {
        "Extracted Excel Values": extractedValues,
      };

      console.log("Sending second API payload:", payload);

      const response = await fetch(
        "https://3bic2tzps3.execute-api.ap-south-1.amazonaws.com/dev/chatbotfile",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        setFormLoading(false);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Second API Response:", result);
      const updatedFormSchema = result.body.message.body.message.form_schema.map(
        (field) => ({
          ...field,
          id: uuidv4(),
        })
      );
      setFormItems(updatedFormSchema);
      setPreviewMode(!previewMode);
      setChatbotInput("");
      setIsChatbotOpen(false);
      setFormLoading(false);
    } catch (error) {
      console.error("Second API Request Failed:", error);
    }
  };

  // const handleFileUpload = (event) => {

  //   const file = event.target.files[0];

  //   if (file) {
  //     const reader = new FileReader();

  //     reader.onload = (e) => {
  //       const fileData = {
  //         name: file.name,
  //         type: file.type,
  //         size: file.size,
  //         content: e.target.result,
  //       };

  //       console.log("Uploaded File Data:", fileData);
  //       message.success(`File uploaded: ${file.name}`);
  //     };

  //     reader.readAsText(file);
  //   }
  // };

  // Function to trigger file explorer

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      convertExcelToBson(file);
      console.log("qqqqqq", file);
    }
  };

  const openFileExplorer = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <style>
        {`
    /* Disable scrolling on the entire page */
    body {
      overflow: hidden;
      font-family: 'Arial', sans-serif;
      background-color: #f4f4f4;
      color: #333;
    }

    /* Overlay for shadowing or blurring the background */
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(8px);
      z-index: 999;
      display: none;
    }

    .overlay.show {
      display: block;
    }

    /* Outer container with scroll enabled */
    .outer-card {
      overflow-y: auto;
      max-height: 90vh;
      padding: 30px; /* Increased padding for better spacing */
      padding-top: 50px; /* Additional top padding */
      padding-bottom: 100px; /* Additional bottom padding */
      border-radius: 12px;
      // box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    }

    /* Inner form container with its own scroll */
    .form-container {
      overflow-y: auto;
      max-height: 50vh;
      padding: 15px;
      // border: 1px solid #e5e7eb;
      border-radius: 8px;
      // background-color: #f9f9f9;
    }

    /* Button styles */
    button {
      border: none;
      border-radius: 8px;
      cursor: pointer;
      padding: 12px 20px;
      transition: all 0.3s ease;
      font-weight: bold;
      font-size: 16px;
    }

    /* Primary button styles */
    .btn-primary {
      background-color: #007bff;
      color: white;
    }
    .btn-primary:hover {
      background-color: #0056b3;
      transform: translateY(-2px);
    }

    /* Success button styles */
    .btn-success {
      background-color: #28a745;
      color: white;
    }
    .btn-success:hover {
      background-color: #218838;
      transform: translateY(-2px);
    }

    /* Chatbot styles */
.chatbot {
      position: fixed;
      top: 50%; /* Center vertically */
      left: 50%; /* Center horizontally */
      transform: translate(-50%, -50%); /* Adjust for the size of the card */
      width: calc(100% - 40px); /* Responsive width with some margin */
      max-width: 500px; /* Maximum width for the chatbot */
      z-index: 1000;
      background-color: #ffffff;
      box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
      border-radius: 12px;
      border: 1px solid #e8e8e8;
    }

    .chatbot-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      border-bottom: 1px solid #f0f0f0;
      background-color: #f9f9f9;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    .chatbot-input {
      display: flex;
      align-items: center;
      padding: 10px;
    }

    .chatbot-input input {
      flex: 1;
      margin-right: 10px;
      border-radius: 6px;
      border: 1px solid #ccc;
      padding: 10px;
      transition: border-color 0.3s ease;
    }
    .chatbot-input input:focus {
      border-color: #007bff;
      outline: none;
    }
  `}
      </style>

      <div style={{ position: "relative" }}>
        {isChatbotOpen && <div className="overlay show"></div>}

        <BreadCrumbs location={props.location} />
        <ToastContainer position="top-center" />

        <div
          style={{
            marginBottom: "55px",
            display: "flex",
            backgroundColor: "white",
            minHeight: "50vh",
            // boxShadow: "0 8px 30px rgba(0, 0, 0, 0.2)",
            // borderRadius: "12px",
            // padding: "30px",
          }}
        >
          <div
            ref={drop}
            style={{
              padding: "20px",
              minHeight: "300px",
              flexGrow: 1,
              margin: "10px",
              // border: "1px solid rgb(43, 46, 74)",
              // borderRadius: "12px",
              width: `${(props) =>
                props.isToolboxOpen
                  ? "calc(100% - 350px)"
                  : "calc(100% - 60px)"}`,
              // backgroundColor: "#f9f9f9",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#2B2E48",
                marginBottom: "20px",
              }}
            >
              <h3
                style={{
                  fontSize: "22px",
                  fontWeight: "600",
                  color: "#333",
                }}
              >
                {previewMode ? "Form Preview" : "Form Builder"}
              </h3>
              <div>
                <Input
                  placeholder="Form Title"
                  style={{
                    width: "100%",
                    padding: "12px 16px",
                    fontSize: "16px",
                    border: "2px solid #e5e7eb",
                    borderRadius: "8px",
                    transition: "all 0.2s ease",
                    // backgroundColor: 'white',
                    color: "#1f2937",
                    textTransform: "capitalize",
                  }}
                  onMouseEnter={(e) => (e.target.style.borderColor = "#3b82f6")}
                  onMouseLeave={(e) => (e.target.style.borderColor = "#e5e7eb")}
                  onChange={formTitleChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center", // Center align buttons
                  alignItems: "center",
                  flexWrap: "wrap", // Ensures buttons wrap on smaller screens
                  gap: "10px", // Reduced spacing for a tighter layout
                }}
              >
                <button
                  onClick={() => setPreviewMode(!previewMode)}
                  className={previewMode ? "btn-primary" : "btn-success"}
                  style={{
                    padding: "8px 14px", // Reduced padding
                    fontSize: "13px", // Smaller font size
                    minWidth: "110px", // Smaller button width
                    borderRadius: "6px", // Softer rounded corners
                    transition: "all 0.2s ease-in-out", // Smooth transition effect
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEye}
                    style={{ marginRight: "5px" }}
                  />
                  {previewMode ? "Back to Edit" : "Preview Form"}
                </button>

                {previewMode && (
                  <>
                    <button
                      onClick={saveForm}
                      className="btn-success"
                      style={{
                        padding: "8px 14px",
                        fontSize: "13px",
                        minWidth: "110px",
                        borderRadius: "6px",
                        transition: "all 0.2s ease-in-out",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faSave}
                        style={{ marginRight: "5px" }}
                      />
                      Save Form
                    </button>
                    <button
                      onClick={handleCopyHtml}
                      className="btn-primary"
                      style={{
                        padding: "8px 14px",
                        fontSize: "13px",
                        minWidth: "110px",
                        borderRadius: "6px",
                        transition: "all 0.2s ease-in-out",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCopy}
                        style={{ marginRight: "5px" }}
                      />
                      Copy as HTML
                    </button>
                  </>
                )}
              </div>
            </div>
            <hr style={{ color: "rgb(43, 46, 74)", marginTop: "20px" }} />
            {formLoading && (
              <div style={{ placeItems: "center", marginTop: "140px" }}>
                <LoadingSpinner />
                <div
                  style={{
                    color: "rgb(43, 46, 74)",
                    alignSelf: "center",
                    fontWeight: "500",
                  }}
                >
                  Loading...
                </div>
              </div>
            )}
            {!formLoading && (
              <div className="form-container">
                <Form layout="vertical">
                  {formItems.map((item, index) => (
                    <FormField
                      style={{ width: "100%" }}
                      key={index}
                      field={item}
                      previewMode={previewMode}
                      onDelete={() =>
                        setFormItems(formItems.filter((i) => i.id !== item.id))
                      }
                      onUpdateLabel={(newLabel) =>
                        setFormItems(
                          formItems.map((i) =>
                            i.id === item.id ? { ...i, label: newLabel } : i
                          )
                        )
                      }
                      onUpdatePlaceholder={(newPlaceholder) =>
                        setFormItems(
                          formItems.map((i) =>
                            i.id === item.id
                              ? { ...i, placeholder: newPlaceholder }
                              : i
                          )
                        )
                      }
                      onUpdateOptions={(newOptions) =>
                        setFormItems(
                          formItems.map((i) =>
                            i.id === item.id ? { ...i, options: newOptions } : i
                          )
                        )
                      }
                    />
                  ))}
                </Form>
              </div>
            )}
          </div>
          <Toolbox onToggle={handleToolboxToggle} />
        </div>

        {isChatbotOpen && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "calc(100% - 300px)",
              maxWidth: "500px",
              zIndex: 1000,
              backgroundColor: "white",
              boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              border: "1px solid #e8e8e8",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 15px",
                borderBottom: "1px solid #f0f0f0",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <RobotOutlined
                  style={{
                    fontSize: "20px",
                    marginRight: "10px",
                    color: "rgb(43, 46, 72)",
                  }}
                />
                <h4
                  style={{
                    margin: 0,
                    fontWeight: 600,
                    color: "rgb(43, 46, 72)",
                  }}
                >
                  Form Generator Assistant
                </h4>
              </div>
              <CloseOutlined
                onClick={() => setIsChatbotOpen(false)}
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.45)",
                }}
              />
            </div>
            <div
              className="chatbot-input"
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              <Input
                onChange={(e) => setChatbotInput(e.target.value)}
                placeholder="Describe your form requirements..."
                value={chatbotInput}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    chatbotSubmit();
                    setIsChatbotOpen(false);
                  }
                }}
                style={{
                  flex: 1,
                  marginRight: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
                autoFocus
              />

              {/*Hidden File Input */}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileUpload} //Calls function when file is selected
              />

              {/* Upload Button */}
              <Button
                icon={<UploadOutlined />}
                onClick={openFileExplorer}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "6px",
                  border: "1px solid #ccc",
                  backgroundColor: "#f8f9fa",
                }}
              />

              {/*Send Button */}
              <Button
                type="primary"
                onClick={() => chatbotInput.length > 0 && chatbotSubmit()}
                icon={<SendOutlined />}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </div>
          </div>
        )}

        <div
          style={{
            position: "fixed",
            bottom: 30,
            right: -130,
            cursor: "pointer",
            zIndex: 1000,
            transition: "all 0.2s ease",
            marginLeft: "200px",
          }}
        >
          <BouncingChatButton
            onClick={() => setIsChatbotOpen(!isChatbotOpen)}
          />
        </div>
      </div>
    </>
  );
};

export default FormBuilder;
